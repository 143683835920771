import { Button, Box, Text, Icon } from "@chakra-ui/react";
import { ChangeEvent, ReactElement, useRef } from "react";
import { FaRegFileAudio } from "react-icons/fa";
import { useModifiedTranslation } from "../../hooks/useModifiedTranslation";
import { RecordedData } from "../../types";

export function RecordingFileButton(props: {
  onClick: (data: RecordedData) => void;
}): ReactElement {
  const t = useModifiedTranslation();
  const height = "80px";
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    const audioData = await extractAudioData(file);
    props.onClick(audioData);
  };

  const extractAudioData = async (file: File): Promise<RecordedData> => {
    const audioContext = new AudioContext();
    const arrayBuffer = await file.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const data = audioBuffer.getChannelData(0);
    const samplingRate = audioBuffer.sampleRate;

    return {
      data: data,
      samplingRate: samplingRate,
      phrase: "calibration",
      isSkipped: false,
    };
  };

  const handleButtonClick = (): void => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".wav"
        onChange={handleFileSelect}
      />
      <Button
        variant="btn_debug"
        width="80%"
        minW="250px"
        height={height}
        margin="0 auto"
        onClick={handleButtonClick}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          position="relative"
        >
          <Icon
            as={FaRegFileAudio}
            color="common_text.light"
            boxSize={8}
            position="absolute"
            left="50%"
            transform="translateX(-140px)"
          />
          <Text fontSize="xl">{t("Recording.fileSelect")}</Text>
        </Box>
      </Button>
    </>
  );
}
